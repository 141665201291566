import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchTopProjectNews } from 'common/redux/commonData/widgets/topProjectWidget/asyncs';
import {
  selectWidgetsTopProject,
  selectWidgetsTopProjectClusterInCardData,
  selectWidgetsTopProjectErrorState,
  selectWidgetsTopProjectInitedState,
  selectWidgetsTopProjectLoadingState,
} from 'common/redux/commonData/widgets/topProjectWidget/selectors';
import { selectClusterPageLoadingState } from 'common/redux/pages/cluster/selectors';
import { TOP_ID_PROJECT } from 'config/constants/tops';
import { useWidgetState } from 'desktop/hooks/useWidgetState';
import { useAppDispatch } from 'store/hooks';

type UseTopProjectNewsWidgetType = (projectId: ProjectType['id']) => {
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета последних новостей.
 * @param projectId - id проекта, для которого загружаются новости.
 */
export const useTopProjectNewsWidget: UseTopProjectNewsWidgetType = (
  projectId,
) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsTopProjectClusterInCardData(projectId),
    shallowEqual,
  );
  const widgetData = useSelector(
    selectWidgetsTopProject(projectId),
    shallowEqual,
  );
  const clusterPageLoading = useSelector(selectClusterPageLoadingState);

  const { isLoading, isError } = useWidgetState({
    selectLoadingState: selectWidgetsTopProjectLoadingState(projectId),
    selectError: selectWidgetsTopProjectErrorState(projectId),
    selectIsInited: selectWidgetsTopProjectInitedState(projectId),
  });

  const fetchData = useCallback(() => {
    if (!widgetData && !clusterPageLoading) {
      dispatch(fetchTopProjectNews({ projectId, topId: TOP_ID_PROJECT }));
    }
  }, [dispatch, projectId, widgetData, clusterPageLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    clusters: clusters.filter(Boolean) as CardData[],
    isLoading,
    isError,
    fetchData,
  };
};
