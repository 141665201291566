import { useCallback, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { fetchRecommendedNews } from 'common/redux/commonData/widgets/recommendationWidget/asyncs';
import {
  selectLoadedRecommendedClusters,
  selectWidgetRecomendedByClusterId,
  selectWidgetsRecomendedClusterInCardData,
  selectWidgetsRecommendedErrorState,
  selectWidgetsRecommendedLoadingState,
} from 'common/redux/commonData/widgets/recommendationWidget/selectors';
import { selectItemExcludedIds } from 'common/redux/selectors';
import { useAppDispatch } from 'store/hooks';

type UseRecommendedNewsWidgetType = (clusterId: CardData['id']) => {
  /**
   * Массив кластеров для отображения
   */
  clusters: CardData[];
  /**
   * Состояние виджета (загружается или нет)
   */
  isLoading: boolean;
  /**
   * Состояние виджета (есть ошибка или нет)
   */
  isError: boolean;
  /**
   * Функция загрузки данных
   */
  fetchData: () => void;
};

/**
 * Хук для виджета рекоммендаций.
 * @param clusterId - id кластера, для которого загружаются новости;
 */
export const useRecommendedNewsWidget: UseRecommendedNewsWidgetType = (
  clusterId,
) => {
  const dispatch = useAppDispatch();

  const clusters = useSelector(
    selectWidgetsRecomendedClusterInCardData(clusterId),
    shallowEqual,
  );
  const isLoading = useSelector(
    selectWidgetsRecommendedLoadingState(clusterId),
  );
  const error = useSelector(selectWidgetsRecommendedErrorState(clusterId));
  const widgetData = useSelector(
    selectWidgetRecomendedByClusterId(clusterId),
    shallowEqual,
  );
  const itemExcludedIds = useSelector(selectItemExcludedIds, shallowEqual);
  const loadedRecommendedClusters = useSelector(
    selectLoadedRecommendedClusters,
    shallowEqual,
  );

  const fetchData = useCallback(() => {
    if (!widgetData) {
      dispatch(
        fetchRecommendedNews({
          clusterId,
          itemExcludedIds: [...itemExcludedIds, ...loadedRecommendedClusters],
        }),
      );
    }
  }, [
    dispatch,
    clusterId,
    widgetData,
    itemExcludedIds,
    loadedRecommendedClusters,
  ]);

  useEffect(() => {
    if (!clusters.length) {
      fetchData();
    }
  }, [fetchData, clusters]);

  return {
    clusters: clusters.filter(Boolean) as CardData[],
    isLoading,
    isError: !!error,
    fetchData,
  };
};
