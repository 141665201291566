import React, { memo, useCallback } from 'react';

import { RCM_BLOCK_TYPE } from 'common/hooks/useRcm';
import { RecEvents } from 'common/RecEvents';

import { Item, ItemPropsType } from '../Item';

type RecItemPropsType = {
  rcmKey: RCM_BLOCK_TYPE;
  clusterId: CardData['id'];
  contextItemId: CardData['id'];
} & ItemPropsType;

/**
 * Компонент новости для виджета топа новостей.
 * @param props.rcmKey - тип блока в котором находятся рекомендации
 * @param props.clusterId - id рекомендованного кластера
 * @param props.contextItemId – id кластера для которого получены рекомендации
 * @param props.url - ссылка на кластер;
 * @param props.title - заголовок кластера;
 * @param props.publicationTime - время публикации;
 * @param props.count - количество комментариев в кластере;
 * @param props.index - индекс айтема для топ100.
 */
export const RecItem = memo(
  ({
    rcmKey,
    clusterId,
    contextItemId,
    url,
    title,
    publicationTime,
    count,
    index,
    onClick,
  }: RecItemPropsType) => {
    const renderRecItem = useCallback(
      (
        ref: React.MutableRefObject<HTMLAnchorElement>,
        onRecClick: () => void,
      ) => (
        <Item
          key={clusterId}
          title={title}
          publicationTime={publicationTime}
          count={count}
          url={url}
          index={index}
          linkRef={ref}
          onClick={() => {
            onRecClick();
            if (onClick) onClick();
          }}
        />
      ),
      [clusterId, count, index, onClick, publicationTime, title, url],
    );

    return (
      <RecEvents
        clusterID={clusterId}
        rcmKey={rcmKey}
        position={index}
        contextItemId={contextItemId}
        // @ts-expect-error: ¯\_(ツ)_/¯
        render={renderRecItem}
      />
    );
  },
);
