import { useState } from 'react';
import { useSelector } from 'react-redux';

type UseWidgetStateType = {
  selectLoadingState: (state: IAppState) => unknown;
  selectError: (state: IAppState) => unknown;
  selectIsInited: (state: IAppState) => unknown;
  isLoadingDefault?: boolean;
};

/**
 * Хук определяющий состояния виджета. Возвращает флаг загрузки и флаг ошибки
 * @param selectLoadingState – селектор возвращающий стэйт виджета если уже был запрос, иначе undefined
 * @param selectError – селектор возвращающий текст ошибки виджета
 * @param selectIsInited – селектор флага был ли виджет инициализирован
 */
export const useWidgetState = ({
  selectLoadingState,
  selectError,
  selectIsInited,
  isLoadingDefault = true,
}: UseWidgetStateType) => {
  const isLoadingInState = useSelector(selectLoadingState);
  const error = useSelector(selectError);
  const isInited = useSelector(selectIsInited);

  const [isLoading, setIsLoading] = useState(isLoadingDefault);

  if (isInited && isLoading !== isLoadingInState) {
    setIsLoading(!!isLoadingInState);
  }

  return {
    isError: !!error,
    isLoading,
    isInited: !!isInited,
  };
};
